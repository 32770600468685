@import "~src/app/css/fonts";


.secondary500BackGroundColor {
    background-color: $secondaryColor500 !important;
}
.secondaryColor500 {
    color: $secondaryColor500 !important;
}

.secondary900BackGroundColor {
    background-color: $secondaryColor900 !important;
}
.secondaryColor900 {
    color: $secondaryColor900 !important;
}

.primaryColor {
    color: $primaryColorHex !important;
}

.primaryBackGroundColor {
    background-color: $primaryColorHex !important;
}

.disabledButtonColour {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
}


.primary500Color {
    color: $primaryColor500 !important;
}

.primary500BackGroundColor {
    background-color: $primaryColor500 !important;
}

// Opacity should be a decimal between 0-1 or 0/1
@mixin primaryBackgroundWithOpacity($opacity) {
    background-color: rgba($primaryColorHex, $opacity);
}

// Opacity should be a decimal between 0-1 or 0/1
@mixin primaryColorWithOpacity($opacity) {
    color: rgba($primaryColorHex, $opacity);
}

// Opacity should be a decimal between 0-1 or 0/1
@mixin customColorWithOpacity($color, $opacity) {
    color: rgba($primaryColorHex, $opacity);
}

.lightPrimaryBackGroundColor {
    background-color: $primaryColor900 !important;
}

.lightestPrimaryBackGroundColor {
    background-color: $primaryColor800 !important;
}
