@import "variables_classes";


.lightColorButton {
    @extend .buttonFont;
    @extend .lightPrimaryBackGroundColor;
    color: white;
}

.lightestColorButton {
    @extend .buttonFont;
    @extend .lightestPrimaryBackGroundColor;
    color: white;
}

.whiteButton {
    @extend .buttonFont;
    background-color: white;
    //border: 1px solid rgba(0, 0, 0, 0.12);
    color: $primaryColorHex !important;
}

.lightestColorButton:disabled, .whiteButton:disabled, .lightColorButton:disabled {
    @extend .disabledButtonColour;
}

.lightGreyButton{
    @extend .buttonFont;
    color: rgba(0, 0, 0, 0.38)!important;
}

.deleteButtonConfirmation {
    @extend .buttonFont;
    background-color: red;
    color: white;
}


