@import "~src/app/css/variables_classes";


.snackBarMessage {
  @extend .subtitle-1;
  @extend .primaryColor;
  background: whitesmoke;

  // this is to allow break lines
  white-space: pre-wrap;

  .mat-simple-snackbar-action {
    color: $primaryColor500 !important;
  }
}
