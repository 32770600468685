// Additional A800, A900 and A1000 have been added

$md-palette: (
    50 : #e4ecee,
    100 : #bcd0d4,
    200 : #8fb1b8,
    300 : #62919c,
    400 : #417a86,
    500 : #1f6271,
    600 : #1b5a69,
    700 : #17505e,
    800 : #124654,
    900 : #0a3442,
    A100 : #7adaff,
    A200 : #47ccff,
    A400 : #14beff,
    A700 : #00b4f9,
    A800: #59A5B6,
    A900: #4D838F,
    A1000: #0295a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$primaryColor500: #1f6271;
$primaryColor800: #59A5B6;
$primaryColor900: #4D838F;
$secondaryColor500: #CDDC39;
$secondaryColor900: #99AA00;

$highchartsColour1: #1F6271;
$highchartsColour2: #99AA00;
$highchartsColour3: #EF7C1A;
$highchartsColour4: #6FC1CF;
$highchartsColour5: #59016F;
$highchartsColour6: #CBCF00;
$highchartsColour7: #CF007C;
$highchartsColour8: #002D9E;
$highchartsColour9: #4E8B43;
$highchartsColour10: #00BDB2;


