@import "theme";

.no-highlight {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

// Stus fonts

.headline-1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 96px;
    line-height: 112px;
    letter-spacing: -1.5px;
    color: rgba(0, 0, 0, 0.87);
}


.headline-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.5px;
    color: rgba(0, 0, 0, 0.87);
}


.headline-3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 56px;

    color: rgba(0, 0, 0, 0.87);
}

.headline-4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.87);
}

.headline-5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: rgba(0, 0, 0, 0.87);
}


.headline-6 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
}


.subtitle-1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
}


.subtitle-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.87);
}


.body-1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
}

.body-2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
}

.body-3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
}

.body-4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    color: $primaryColor500;
}

.body-6 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: $primaryColor500;
}

.body-7 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: $primaryColor500;
}

.caption {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
}


.overline {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
}

.error {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #ff0000;
}

.buttonFont {
    font-family: Roboto;
    text-transform: uppercase;
    letter-spacing: 1.25px;
}


.hyperlink {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $primaryColor500;
}


.boldFont {
    font-family: Roboto;
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
}

